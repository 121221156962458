import { mapHelper } from 'common/utils'

// 发票性质
const orderInvoiceType = [
  {
    text: '普通',
    value: 1
  },
  {
    text: '红冲',
    value: 2
  }
]

const {
  setOps: orderInvoiceTypeOps
} = mapHelper.setMap(orderInvoiceType)

export {
  orderInvoiceTypeOps
}
