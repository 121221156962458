<template>
  <div class="ElectronicInvoiceList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-input label="订单编号" v-model="searchParams.serialNumber"></v-input>
        <v-input label="业主姓名" v-model="searchParams.userName"></v-input>
        <v-input label="业主手机" v-model="searchParams.userMobile"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 v-if="getShowRegion" :width="160" label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-datepicker
          label="开票时间"
          type="rangedatetimer"
          :maxDate="maxDate"
          :startTime.sync="searchParams.invoiceTime1"
          :endTime.sync="searchParams.invoiceTime2">
        </v-datepicker>
        <v-select label="发票性质" v-model="searchParams.orderInvoiceType" :options="orderInvoiceTypeOps"></v-select>
      </template>
    </list>
  </div>
</template>

<script>
import { regionParams, communityParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'
import { orderInvoiceTypeOps } from './map'
import { createAlinkVNode, createHTMLVNode } from 'common/vdom'
import moment from 'moment'

export default {
  name: 'ElectronicInvoiceList',
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      orderInvoiceTypeOps: orderInvoiceTypeOps(1),
      regionParams: regionParams,
      communityParams: communityParams,
      searchParams: {
        serialNumber: '',
        userName: '',
        userMobile: '',
        communityId: '',
        regionId: '',
        invoiceTime1: '',
        invoiceTime2: '',
        orderInvoiceType: undefined
      },
      headers: [
        {
          prop: 'serialNumberVNode',
          label: '订单编号',
          minWidth: '100',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.serialNumber,
              cb: this.lookOrderInfo
            })
          }
        },
        {
          prop: 'orderMoney',
          label: '订单金额'
        },
        {
          prop: 'realMoney',
          label: '实付金额'
        },
        {
          prop: 'invoiceMoney',
          label: '开票金额'
        },
        {
          prop: 'invoiceTitle',
          label: '发票抬头'
        },
        {
          prop: 'userInfoH5',
          label: '业主信息',
          formatter: (row, prop) => {
            return createHTMLVNode(this, row.userInfo)
          }
        },
        {
          prop: 'intime',
          label: '开票时间'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'orderInvoiceType',
          label: '发票性质'
        }
      ]
    }
  },

  computed: {
    // 根据账户角色权限，获取是否要显示公司
    getShowRegion () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    }
  },
  methods: {

    lookOrderInfo (row) {
      this.$router.push({
        name: 'orderEvaluateForm',
        query: {
          id: row.id,
          serialNumber: row.serialNumber,
          isInvoice: '1'
        }
      })
    }
  }
}
</script>
